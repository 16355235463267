<template>
  <body class="page-header-fixed page-quick-sidebar-over-content">
        <div class="page-header navbar navbar-fixed-top">

            <div class="page-header-inner">

                <div class="page-logo">
                   <!-- <a href="index.html">
                        <img src="../../assets/eft/img/logos/logonuevo.png" alt="logo" class="logo-default" />
                    </a>-->
                    <h3>ADMINISTRACIÓN</h3>
                    <div class="menu-toggler sidebar-toggler hide">
                        <!-- DOC: Remove the above "hide" to enable the sidebar toggler button on header -->
                    </div>
                </div>

                <a href="javascript:;" class="menu-toggler responsive-toggler" data-toggle="collapse" data-target=".navbar-collapse">
                </a>

                <div class="top-menu">
                    <ul class="nav navbar-nav pull-right">
                        <!-- <li class="dropdown dropdown-extended dropdown-notification" id="header_notification_bar">
                            <a href="#" class="dropdown-toggle" data-toggle="dropdown" data-hover="dropdown" data-close-others="true">
                                <i class="icon-bell"></i>
                                <span class="badge badge-default"> 7 </span>
                            </a>
                            <ul class="dropdown-menu">
                                <li>
                                    <p>You have 14 new notifications</p>
                                </li>
                                <li>
                                    <ul class="dropdown-menu-list scroller" style="height: 250px">
                                        <li>
                                            <a href="#">
                                                <span class="label label-sm label-icon label-success">
                                <i class="fa fa-plus"></i>
                              </span> New user registered.
                                                <span class="time"> Just now </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <span class="label label-sm label-icon label-danger">
                                <i class="fa fa-bolt"></i>
                              </span> Server #12 overloaded.
                                                <span class="time"> 15 mins </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <span class="label label-sm label-icon label-warning">
                                <i class="fa fa-bell-o"></i>
                              </span> Server #2 not responding.
                                                <span class="time"> 22 mins </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <span class="label label-sm label-icon label-info">
                                <i class="fa fa-bullhorn"></i>
                              </span> Application error. <span class="time"> 40 mins </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <span class="label label-sm label-icon label-danger">
                                <i class="fa fa-bolt"></i>
                              </span> Database overloaded 68%.
                                                <span class="time"> 2 hrs </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <span class="label label-sm label-icon label-danger">
                                <i class="fa fa-bolt"></i>
                              </span> 2 user IP blocked. <span class="time"> 5 hrs </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <span class="label label-sm label-icon label-warning">
                                <i class="fa fa-bell-o"></i>
                              </span> Storage Server #4 not responding.
                                                <span class="time"> 45 mins </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <span class="label label-sm label-icon label-info">
                                <i class="fa fa-bullhorn"></i>
                              </span> System Error. <span class="time"> 55 mins </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <span class="label label-sm label-icon label-danger">
                                <i class="fa fa-bolt"></i>
                              </span> Database overloaded 68%.
                                                <span class="time"> 2 hrs </span>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="external">
                                    <a href="#">
                          See all notifications <i class="m-icon-swapright"></i>
                        </a>
                                </li>
                            </ul>
                        </li> -->

                        <!-- <li class="dropdown dropdown-extended dropdown-inbox" id="header_inbox_bar">
                            <a href="#" class="dropdown-toggle" data-toggle="dropdown" data-hover="dropdown" data-close-others="true">
                                <i class="icon-envelope-open"></i>
                                <span class="badge badge-default"> 4 </span>
                            </a>
                            <ul class="dropdown-menu">
                                <li>
                                    <p>You have 12 new messages</p>
                                </li>
                                <li>
                                    <ul class="dropdown-menu-list scroller" style="height: 250px">
                                        <li>
                                            <a href="inbox.html?a=view">
                                                <span class="photo">
                                <img
                                  src="../../assets/admin/layout/img/avatar2.jpg"
                                  alt=""
                                />
                              </span>
                                                <span class="subject">
                                <span class="from"> Lisa Wong </span>
                                                <span class="time"> Just Now </span>
                                                </span>
                                                <span class="message">
                                Vivamus sed auctor nibh congue nibh. auctor nibh auctor
                                nibh...
                              </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="inbox.html?a=view">
                                                <span class="photo">
                                <img
                                  src="../../assets/admin/layout/img/avatar3.jpg"
                                  alt=""
                                />
                              </span>
                                                <span class="subject">
                                <span class="from"> Richard Doe </span>
                                                <span class="time"> 16 mins </span>
                                                </span>
                                                <span class="message">
                                Vivamus sed congue nibh auctor nibh congue nibh. auctor
                                nibh auctor nibh...
                              </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="inbox.html?a=view">
                                                <span class="photo">
                                <img
                                  src="../../assets/admin/layout/img/avatar1.jpg"
                                  alt=""
                                />
                              </span>
                                                <span class="subject">
                                <span class="from"> Bob Nilson </span>
                                                <span class="time"> 2 hrs </span>
                                                </span>
                                                <span class="message">
                                Vivamus sed nibh auctor nibh congue nibh. auctor nibh
                                auctor nibh...
                              </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="inbox.html?a=view">
                                                <span class="photo">
                                <img
                                  src="../../assets/admin/layout/img/avatar2.jpg"
                                  alt=""
                                />
                              </span>
                                                <span class="subject">
                                <span class="from"> Lisa Wong </span>
                                                <span class="time"> 40 mins </span>
                                                </span>
                                                <span class="message">
                                Vivamus sed auctor 40% nibh congue nibh...
                              </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="inbox.html?a=view">
                                                <span class="photo">
                                <img
                                  src="../../assets/admin/layout/img/avatar3.jpg"
                                  alt=""
                                />
                              </span>
                                                <span class="subject">
                                <span class="from"> Richard Doe </span>
                                                <span class="time"> 46 mins </span>
                                                </span>
                                                <span class="message">
                                Vivamus sed congue nibh auctor nibh congue nibh. auctor
                                nibh auctor nibh...
                              </span>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="external">
                                    <a href="inbox.html">
                          See all messages <i class="m-icon-swapright"></i>
                        </a>
                                </li>
                            </ul>
                        </li> -->

                        <!-- <li class="dropdown dropdown-extended dropdown-tasks" id="header_task_bar">
                            <a href="#" class="dropdown-toggle" data-toggle="dropdown" data-hover="dropdown" data-close-others="true">
                                <i class="icon-calendar"></i>
                                <span class="badge badge-default"> 3 </span>
                            </a>
                            <ul class="dropdown-menu extended tasks">
                                <li>
                                    <p>You have 12 pending tasks</p>
                                </li>
                                <li>
                                    <ul class="dropdown-menu-list scroller" style="height: 250px">
                                        <li>
                                            <a href="page_todo.html">
                                                <span class="task">
                                <span class="desc"> New release v1.2 </span>
                                                <span class="percent"> 30% </span>
                                                </span>
                                                <div class="progress">
                                                    <div style="width: 40%" class="progress-bar progress-bar-success" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100">
                                                        <div class="sr-only">40% Complete</div>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="page_todo.html">
                                                <span class="task">
                                <span class="desc"> Application deployment </span>
                                                <span class="percent"> 65% </span>
                                                </span>
                                                <div class="progress progress-striped">
                                                    <div style="width: 65%" class="progress-bar progress-bar-danger" aria-valuenow="65" aria-valuemin="0" aria-valuemax="100">
                                                        <div class="sr-only">65% Complete</div>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="page_todo.html">
                                                <span class="task">
                                <span class="desc"> Mobile app release </span>
                                                <span class="percent"> 98% </span>
                                                </span>
                                                <div class="progress">
                                                    <div style="width: 98%" class="progress-bar progress-bar-success" aria-valuenow="98" aria-valuemin="0" aria-valuemax="100">
                                                        <div class="sr-only">98% Complete</div>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="page_todo.html">
                                                <span class="task">
                                <span class="desc"> Database migration </span>
                                                <span class="percent"> 10% </span>
                                                </span>
                                                <div class="progress progress-striped">
                                                    <div style="width: 10%" class="progress-bar progress-bar-warning" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100">
                                                        <div class="sr-only">10% Complete</div>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="page_todo.html">
                                                <span class="task">
                                <span class="desc"> Web server upgrade </span>
                                                <span class="percent"> 58% </span>
                                                </span>
                                                <div class="progress progress-striped">
                                                    <div style="width: 58%" class="progress-bar progress-bar-info" aria-valuenow="58" aria-valuemin="0" aria-valuemax="100">
                                                        <div class="sr-only">58% Complete</div>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="page_todo.html">
                                                <span class="task">
                                <span class="desc"> Mobile development </span>
                                                <span class="percent"> 85% </span>
                                                </span>
                                                <div class="progress progress-striped">
                                                    <div style="width: 85%" class="progress-bar progress-bar-success" aria-valuenow="85" aria-valuemin="0" aria-valuemax="100">
                                                        <div class="sr-only">85% Complete</div>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="page_todo.html">
                                                <span class="task">
                                <span class="desc"> New UI release </span>
                                                <span class="percent"> 18% </span>
                                                </span>
                                                <div class="progress progress-striped">
                                                    <div style="width: 18%" class="progress-bar progress-bar-important" aria-valuenow="18" aria-valuemin="0" aria-valuemax="100">
                                                        <div class="sr-only">18% Complete</div>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="external">
                                    <a href="page_todo.html">
                          See all tasks <i class="icon-arrow-right"></i>
                        </a>
                                </li>
                            </ul>
                        </li> -->

                        <li class="dropdown dropdown-user">
                            <a href="#" class="dropdown-toggle" data-toggle="dropdown" data-hover="dropdown" data-close-others="true">
                                <img alt="" class="img-circle hide1" src="../../assets/admin/layout/img/avatar.png" />
                                <span class="username username-hide-on-mobile"> Nick </span>
                                <i class="fa fa-angle-down"></i>
                            </a>
                            <ul class="dropdown-menu">
                                <!-- <li>
                                    <a href="extra_profile.html">
                                        <i class="icon-user"></i> My Profile
                                    </a>
                                </li>
                                <li>
                                    <a href="page_calendar.html">
                                        <i class="icon-calendar"></i> My Calendar
                                    </a>
                                </li>
                                <li>
                                    <a href="inbox.html">
                                        <i class="icon-envelope-open"></i> My Inbox
                                        <span class="badge badge-danger"> 3 </span>
                                    </a>
                                </li>
                                <li>
                                    <a href="page_todo.html">
                                        <i class="icon-rocket"></i> My Tasks
                                        <span class="badge badge-success"> 7 </span>
                                    </a>
                                </li>
                                <li class="divider"></li>
                                <li>
                                    <a href="extra_lock.html">
                                        <i class="icon-lock"></i> Lock Screen
                                    </a>
                                </li> -->
                                <li>
                                    <a @click="logOut"> <i class="icon-key"></i> Log Out </a>
                                </li>
                            </ul>
                        </li>

                        <li class="dropdown dropdown-quick-sidebar-toggler">
                            <a @click="logOut" class="dropdown-toggle">
                                <i class="icon-logout"></i>
                            </a>
                        </li>
                        
                    </ul>
                </div>

            </div>

        </div>

        <div class="clearfix">
        </div>

        <div class="page-container">

            <div class="page-sidebar-wrapper">
                <div class="page-sidebar navbar-collapse collapse">

                    <ul class="page-sidebar-menu" data-auto-scroll="true" data-slide-speed="200">
                        <li class="sidebar-toggler-wrapper">
                            <div class="sidebar-toggler"></div>
                        </li>

                        <!-- <li class="sidebar-search-wrapper">
                            <form class="sidebar-search" action="extra_search.html" method="POST">
                                <a href="javascript:;" class="remove">
                                    <i class="icon-close"></i>
                                </a>
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Search..." />
                                    <span class="input-group-btn">
                                        <a href="javascript:;" class="btn submit"><i class="icon-magnifier"></i></a>
                                    </span>
                                </div>
                            </form>
                        </li> -->

                        <li>
                            <router-link active-class="active" to="/adminPersonal">
                                <!--<i class="icon-home"></i>-->
                                <span class="title">Admin Personal</span>
                                <span class="selected"></span>
                                <span class="arrow open"></span>
                            </router-link>
                        </li>

                        <!-- <li>
                            <router-link active-class="active" to="/AdminDependencias">
                                <i class="icon-home"></i>
                                <span class="title">Admin Dependencias</span>
                                <span class="selected"></span>
                                <span class="arrow open"></span>
                            </router-link>
                        </li> -->


                        <li v-for="functionality in functionalities" :key="functionality.Name">
                            <router-link active-class="active" :to="functionality.Name">
                                <span class="title">{{ functionality.Caption}}</span>
                                <span class="selected"></span>
                                <span class="arrow open"></span>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="page-content-wrapper">
                <div class="page-content">
                    <!-- <h3 class="page-title">
                        Editable Datatables <small>editable datatable samples</small>
                    </h3>
                    <div class="page-bar">
                        <ul class="page-breadcrumb">
                            <li>
                                <i class="fa fa-home"></i>
                                <a href="index.html">Home</a>
                                <i class="fa fa-angle-right"></i>
                            </li>
                            <li>
                                <a href="#">Data Tables</a>
                                <i class="fa fa-angle-right"></i>
                            </li>
                            <li>
                                <a href="#">Editable Datatables</a>
                            </li>
                        </ul>
                        <div class="page-toolbar">
                        </div>
                    </div> -->
                    <router-view name='admin-content'></router-view>
                </div>
            </div>

        </div>

        <div class="page-footer">
            <div class="page-footer-inner">2021 &copy; Estado Fuerza by Eon Innovaction.</div>
            <div class="scroll-to-top">
                <i class="icon-arrow-up"></i>
            </div>
        </div>
  </body>
</template>

<script>
  import { EventBus } from '../../helpers/EventBus.js';
  import { Metronic } from '../../components/layout/global/metronic.js';
  import { Layout } from '../../components/layout/admin/layout.js';

  export default {
    name: "admin",
    data(){
        return {
            /* functionalities: [
                {
                    Caption: "Admin. Dependencias",
                    Icon: "",
                    Name: "/AdminDependencias",
                    NameComponent: "",
                    NameComponentPrincipal: "mainContent",
                    Position: ""
                }
            ], */
        };
    },

    watch: {
      $route(to, from) {
          let operation = to.path.substring(1 , to.path.length);
          if (operation == 'adminPersonal' ) {
              return;
          }

          let functionality = this.functionalities.find(element => element.Name == to.path);
          this.$store.dispatch("jlcrud/setCatalog", {
              caption : functionality.Caption,
              name:operation
          });
      }
   },
   computed: {
      functionalities(){
          //console.log(this.$store.getters['account/getCurrentUser'].funcionalidades);
         return this.$store.getters['account/getCurrentUser'].funcionalidades;
      },
   },

    
    methods: {
        logOut() {
            this.$session.destroy();
            this.$store.dispatch("account/logOut");
            this.$router.push('/account');
        },
    },

    beforeCreate: function() {
        if (!this.$session.exists() || (typeof this.$store.getters['account/getCurrentUser']  === 'undefined')) {
            this.$session.destroy();
            this.$router.push('/account');
        }
    },
    
    mounted() {
        jQuery(document).ready(function() {
            Metronic.init();
            Layout.init();
        });
    }
  };
</script>

<style lang="css">
  @import url("../../assets/admin/layout/css/themes/default.css");
  @import url("./admin.css");
</style>