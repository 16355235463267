<template>
  <body class="account">
      <div class="logo">
          <a href="index.html">
              <img src="../../assets/eft/layout/img/logos/logonuevo.png" alt="" />
          </a>
      </div>
      <div class="menu-toggler sidebar-toggler">
      </div>
      <div class="content"> 
          <router-view name='account-content'></router-view>
      </div>
      <div class="copyright">
          2021 &copy; Eon Innovaction. Account Template.
      </div>
  </body>
</template>

<script>
  export default {
    name: "account",
    data() {
      return {
      };
    },
    methods: {
    },
    created() {
    },
    beforeCreate: function () {
      if (this.$session.exists()) {
        this.$router.push('/admin');
      }else{
        this.$router.push('/login');
      }
    },
  };
</script>
<style lang="css">
    @import url("./account.css");
</style>