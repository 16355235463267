/* css */
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'element-ui/lib/theme-chalk/index.css';
import '../assets/global/css/components.css';
import '../assets/global/css/plugins.css';
import '../assets/admin/layout/css/layout.css';
import '../assets/eft/layout/css/style.css';

/* js */
import jQuery from 'jquery';
import bootstrap from 'bootstrap/dist/js/bootstrap.min.js';
import Vue from 'vue';
import VueSession from 'vue-session';
import ElementUI from "element-ui";
import ElementUIlocale from 'element-ui/lib/locale/lang/es.js';
import VueSimpleAlert from 'vue-simple-alert';
import router from '../router/routes.js';
import store from '../store/store.js';
import App from './App.vue';



Vue.use(VueSession);
Vue.use(ElementUI, { ElementUIlocale });
Vue.use(VueSimpleAlert);

new Vue({
    router,
    el: '#app',
    router,
    store,
    render: h => h(App)
        /* beforeCreate: function() {
            if (this.$session.exists()) {
                //window.location = 'admin.html';
            } else {
                //window.location = 'account.html';
            }
        } */
});