import GLOBALS from "../app/globals.ts";
import { Utils } from './utils-service.js';

export const JlCrudService = {

    getStructure: function(catalog, Token) {
        return Utils.httpGet(
            GLOBALS.API_URL + "/PoliciaCuadrantes/Administracion/" + catalog + "/estructura/1",
            Token
        );
    },

    get: function(catalog, Token) {
        return Utils.httpGet(
            GLOBALS.API_URL + "PoliciaCuadrantes/Administracion/" + catalog + "/",
            Token
        );
    },

    save: function(catalog, record, id, operation, Token) {

        if (typeof record === 'undefined') {
            return;
        }

        if (operation == 1 || (typeof(id) === 'undefined') || id == 0) {
            console.log("ADD: " + GLOBALS.API_URL + "/PoliciaCuadrantes/Administracion/" + catalog + "/");
            console.log("RECORD: " + JSON.stringify(record));

            return Utils.httpPost(
                GLOBALS.API_URL + "PoliciaCuadrantes/Administracion/" + catalog + "/",
                record,
                Token
            );

        } else {
            console.log("UPDATE: " + GLOBALS.API_URL + "/PoliciaCuadrantes/Administracion/" + catalog + "/" + id);
            console.log("RECORD: " + JSON.stringify(record));

            return Utils.httpUpdate(
                GLOBALS.API_URL + "PoliciaCuadrantes/Administracion/" + catalog + "/" + id,
                record,
                Token
            );
        }
    },

    save_: function(catalog, record, id, Token) {

        if (typeof record === 'undefined') {
            return;
        }

        if (typeof(id) === 'undefined' || id == 0) {
            console.log("ADD: " + GLOBALS.API_URL + "/PoliciaCuadrantes/Administracion/" + catalog + "/");
            console.log("RECORD: " + JSON.stringify(record));

            return Utils.httpPost(
                GLOBALS.API_URL + "PoliciaCuadrantes/Administracion/" + catalog + "/",
                record,
                Token
            );

        } else {
            console.log("UPDATE: " + GLOBALS.API_URL + "/PoliciaCuadrantes/Administracion/" + catalog + "/" + id);
            console.log("RECORD: " + JSON.stringify(record));

            return Utils.httpUpdate(
                GLOBALS.API_URL + "PoliciaCuadrantes/Administracion/" + catalog + "/" + id,
                record,
                Token
            );
        }
    },

    delete: function(catalog, id, Token) {
        if (typeof(id) === 'undefined' || id == 0) {
            throw "El id enviado es no valido";
        }

        console.log("DELETE: " + GLOBALS.API_URL + "PoliciaCuadrantes/Administracion/" + catalog + "/");
        console.log("RECORD: " + JSON.stringify(id));

        return Utils.httpDelete(
            GLOBALS.API_URL + "PoliciaCuadrantes/Administracion/" + catalog + "/" + id,
            Token
        );
    }
}