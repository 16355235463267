export const catalogs = {
    /* cattipoinstancia: "AdminInstancias",
    catTipoAmbito: "AdminCatTipoAmbito", */
    /* ********** */
    catdependencia: "AdminDependencias",
    catCantidadSP: "AdminCantidadSP",
    catLadoSP: "AdminLadoSP",
    catRegionSP: "AdminRegionSP",
    catTipoSP: "AdminTipoSP",
    catVistaSP: "AdminVistaSP",
    catAreas: "AdminArea",
    cattipoinstancia: "AdminInstancias",
    puestos: "AdminPuestos",
    PuestosDependencia: "AdminPuestosDependencia",
    catTipoHabilidad: "AdminTipoHabilidad",
    catRamasDependencias: "AdminRamasDependencia",
    catTipoRegion: "AdminTipoRegion",
    catRegiones: "AdminCatRegion",
    catTipoSede: "AdminCatTipoSede",
    catSede: "AdminCatSede",
    subSedes: "AdminCatSubSedes",
    catDocumentos: "AdminCatDocumentos",
    catEstatusEstudio: "AdminCatEstatusEstudio",
    catestudios: "AdminCatEstudios",
    catmarcavehiculo: "AdminMarcas",
    catmodelovehiculo: "AdminModelos",
    catcategoria: "AdminCategorias",
    catcolonias: "AdminColonias",
    catcolorvehiculo: "AdminColorVehiculo",
    catdesccatalogo: "AdminMediafiliacion",
    catestados: "AdminEstados",
    caticono: "AdminIcono",
    catmunicipios: "AdminMunicipios",
    catnombcatalogo: "AdminRasgos",
    catperfil: "AdminPerfiles",
    catpreguntas: "AdminPreguntas",
    catsubcategoria: "AdminSubcategoria",
    usuario: "AdminUsuarios",
    perfil: "AdminPerfilesApp",
    PerfilUsuario: "AdminPerfilesUsuario",
    permisosApplication: "AdminPermisosApp",
    PermisosPerfil: "AdminPermisosPerfil",
    tipoVehiculo: "AdminTipoVehiculo",
    usoVehiculo: "AdminUsoVehiculo",
    catdelito: "AdminDelitos",
    catdenuncia: "AdminDenuncias",
    cathecho: "AdminHechos",
    catseguimiento: "AdminCatSeguimiento",
    catGradoHomologado: "AdminCatGradoHomologado",
    catOcupacion: "AdminCatOcupacion",
    catsexo: "AdminCatSexo",
    catTipoFuncion: "AdminCatTipoFuncion",
    catestatususuario: "AdminCatEstatusUsuario",
    catTipoPermutacion: "AdminCatTipoPermutacion",
    catTipoPuesto: "AdminCatTipoPuesto",
    catTipoReferencia: "AdminCatTipoReferencia",
    catTurno: "AdminCatTurno",
    catTipoRecurso: "AdminCatTipoRecurso",
    catTipoNacionalidad: "AdminCatTipoNacionalidad",
    catTipoFaltaAdmin: "AdminCatTipoFaltaAdministrativa",
    catTipoBiometrico: "AdminCatTipoBiometrico",
    catTipoAmbito: "AdminCatTipoAmbito",
    catBienJuridico: "AdminCatBienJuridico",
    catComprobanteEstudio: "AdminComprobanteEstudio",
    catDelitoCes: "AdminDelitoCes",
    catSubDelito: "AdminSubDelitoCes",
    catEstadoCivil: "AdminEstadoCivil",
    catestatusdocumento: "AdminEstatusDocumentos",
    catestatusproceso: "AdminEstatusProceso",
    catEstatusUbicacion: "AdminEstatusUbicacion",
    catHabilitado: "AdminHabilitado",
    catModalidad: "AdminModalidad",
    catMotivoBaja: "AdminMotivoBaja",
    catMotivoFaltaAdmin: "AdminMotivoFaltaAdministrativa",
    catNivel: "AdminNivel",
    catCalibre: "AdminCalibre",
    catClase: "AdminClase",
    catEstatusArma: "AdminEstatusArma",
    catEstatusCargador: "AdminEstatusCargador",
    catEstatusMunicion: "AdminEstatusMunicion",
    catMarca: "AdminMarcaArma",
    catModelo: "AdminModeloArma",
    catMotivoUso: "AdminMotivoUso",
    catProcedenciaArma: "AdminProcedenciaArma",
    catTipo: "AdminTipoArma",
    catTipoAsignacion: "AdminTipoAsignacion",
    catTipoEntrega: "AdminTipoEntrega",
    catTipoItemArmeria: "AdminTipoItem",
    catItemArmeria: "AdminItem",
    catActosRelevantes: "AdminActosRelevantes",
    catCondecoracion: "AdminCondecoraciones",
    catEstatusProcedimiento: "AdminEstatusProcedimiento",
    catFrecuenciaMPro: "AdminFrecuenciaProductividad",
    catFrecuenciaMRP: "AdminFrecuenciaRespetoPrincipios",
    catFunciones: "AdminFunciones",
    catIncidencia: "AdminIncidencia",
    catIndicador: "AdminIndicador",
    catPeriodicidad: "AdminPeriodicidad",
    catPrincipios: "AdminPrincipio",
    catProcedimiento: "AdminProcedimiento",
    catReactivo: "AdminReactivo",
    catResultado: "AdminResultado",
    catTipoConvocatoria: "AdminTipoConvocatoria",
    catTipoEvaluacion: "AdminTipoEvaluacion",
    catTipoRecompensa: "AdminTipoRecompensa",
    catValores: "AdminValores",
    reactivoPrincipio: "AdminReactivoPrincipio",
    reactivoValores: "AdminReactivoValores",
    indicadorEvaluacion: "AdminIndicadorEvaluacion",
    actosRelevantesEvaluacion: "AdminActosRelevantesEvaluacion",
    catCriterioEvaluacion: "AdminCriterioEvaluacion",
    catDescripcion: "AdminDescripcion",
    catEstatusEvaluacion: "AdminEstatusEvaluacion",
    catHabilidades: "AdminHabilidades",
    catPrincipiosConstitucionales: "AdminPrincipiosConstitucionales",
    reactivosEvaluacion: "AdminReactivoEvaluacion",
    descripcionCriterio: "AdminDescripcionCriterio",
    catValoresCapacidades: "AdminValoresCapacidades",
    catEstatusEquipo: "AdminEstatusEquipo",
    catEstatusMovimeintoEquipo: "AdminEstatusMovimientoEquipo",
    catItem: "AdminItemEquipamiento",
    catTipoItem: "AdminTipoItemEquipamiento",
    catTipoMovimientoEquipamiento: "AdminTipoMovimiento",
    catAulas: "AdminAulas",
    catCapacitadores: "AdminCapacitadores",
    catCategoria: "AdminCategoria",
    catEstatus: "AdminResultadoCurso",
    catEstatusAula: "AdminEstatusAula",
    catEstatusCurso: "AdminEstatusCurso",
    catSubcategoria: "AdminCurso",
    catTipoCurso: "AdminTipoCurso"
};